import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { NgVarDirective } from '../directives/ng-var.directive';
import { HeaderComponent } from './header/header.component';
import { LanguageSelectionComponent } from './language-selection/language-selection.component';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';

@NgModule({
  declarations: [
    HeaderComponent,
    LanguageSelectionComponent,
    ProfileDropdownComponent,
  ],
  exports: [HeaderComponent],
  imports: [
    CommonModule,
    FontAwesomeModule,
    NgOptimizedImage,
    RouterModule,
    NgbDropdownModule,
    NgVarDirective,
  ],
})
export class HeaderModule {}
