import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil, tap } from 'rxjs';

import { nonNullish } from '../lib/non-nullish';
import { AnalyticsService } from './analytics/analytics.service';
import { ApplicationSettingsService } from './application-settings/service/application-settings.service';
import { Patient } from './appointment-creation/patient/api/model/patient';
import { AuthenticationService } from './authentication/service/authentication.service';
import { CookieService } from './authentication/service/cookie.service';
import { ConfigService } from './config.service';
import { EventDispatcher } from './event-dispatcher/event-dispatcher';
import { BsgPageviewAuthenticatedEvent } from './event-dispatcher/events/bsg-pageview-authenticated-event';
import { BsgPageviewUnauthenticatedEvent } from './event-dispatcher/events/bsg-pageview-unauthenticated-event';
import { LanguageService } from './language/service/language.service';
import { MedicalCenterService } from './medical-center/service/medical-center.service';

declare global {
  interface Window {
    useremail: string | null;
    userphone: string | null;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  private readonly unsubscribeAll$ = new Subject<void>();

  logoDataUrl = '';
  brandName = '';
  favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');
  title: HTMLTitleElement | null = document.querySelector('#appTitle');

  constructor(
    readonly analyticsService: AnalyticsService,
    readonly medicalCenterService: MedicalCenterService,
    private readonly applicationSettingsService: ApplicationSettingsService,
    private readonly router: Router,
    private readonly eventDispatcher: EventDispatcher,
    private readonly authenticationService: AuthenticationService,
    private readonly languageService: LanguageService,
    private readonly cookieService: CookieService,
  ) {
    analyticsService.init();
    medicalCenterService.doLegacyRoutingIfNeeded();

    this.applicationSettingsService.applicationSettings$.subscribe(
      (applicationSettings) => {
        this.logoDataUrl = applicationSettings.logo;
        this.brandName = applicationSettings.brandName;
        if (this.favIcon) this.favIcon.href = applicationSettings.logo;
        if (this.title) this.title.text = applicationSettings.brandName;
      },
    );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap((event) => {
          if (event instanceof NavigationEnd) {
            const urlParts = this.determineUrlPart(event.url);
            const path = urlParts[0];
            const mc_name = this.determineMedicalCenterName(urlParts[1]);
            this.dispatchBsgPageviewEvents(path, mc_name);
          }
        }),
        takeUntil(this.unsubscribeAll$),
      )
      .subscribe();

    this.cookieService
      .createOrUpdateCookie()
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }
  private determineUrlPart(url: string) {
    return url.split('?');
  }

  private determineMedicalCenterName(queryParams: string) {
    return queryParams ? queryParams.split('&rt=')[0].split('=')[1] || '' : '';
  }

  private dispatchBsgPageviewEvents(path: string, mc_name: string) {
    if (this.authenticationService.patientData$.value) {
      this.dispatchAuthenticatedEvent(
        nonNullish(this.authenticationService.patientData$.value),
        this.authenticationService.userId$.value,
        path,
        mc_name,
      );
    } else {
      this.eventDispatcher.dispatch(
        new BsgPageviewUnauthenticatedEvent({
          path: path,
          mc_name: mc_name,
        }),
      );
    }
  }

  private dispatchAuthenticatedEvent(
    patientData: Patient,
    userId: number | null,
    path: string,
    mc_name: string,
  ) {
    this.eventDispatcher.dispatch(
      new BsgPageviewAuthenticatedEvent({
        lang: this.languageService.getLanguage(),
        site: ConfigService.regionalResourceCode(),
        userId: userId?.toString() ?? '',
        patientUuid: patientData.uuid,
        email: patientData.email,
        phone: patientData.phoneNumber,
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        gender: patientData.gender,
        city: patientData.address.city,
        postalCode: patientData.address.zipCode,
        country: this.languageService.getIsoCountryCode(
          patientData.address.country,
        ),
        path: path,
        mc_name: mc_name,
      }),
    );
  }
}
