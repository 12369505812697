import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { map, Observable, of } from 'rxjs';

import { AuthenticationService } from '../service/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard {
  constructor(private readonly authenticationService: AuthenticationService) {}

  canActivate(): Observable<boolean> {
    if (!localStorage.getItem('token')) {
      return this.authenticationService
        .logout('/auth/login', true)
        .pipe(map(() => false));
    }
    return of(true);
  }
}

export const canActivate: CanActivateFn = () =>
  inject(AuthenticationGuard).canActivate();
