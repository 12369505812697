<ng-template #content let-modal>
  <app-language-selection
    (modalClose)="modal.dismiss()"
  ></app-language-selection>
</ng-template>
<header class="border-bottom">
  <span aria-hidden="true" hidden="true" id="useremail">{{
    useremail$ | async
  }}</span>
  <span aria-hidden="true" hidden="true" id="userphone">{{
    userphone$ | async
  }}</span>
  <nav class="navbar navbar-expand-lg header">
    <div class="container">
      <a
        class="navbar-brand w-auto cursor-pointer"
        [routerLink]="['locations']"
      >
        <img alt="logo" [src]="logoDataUrl$ | async" width="105" height="24" />
      </a>
      <div class="d-flex flex-row">
        <div
          class="d-flex gap-2 align-items-center cursor-pointer"
          (click)="modalService.open(content, { fullscreen: true })"
        >
          <div class="country-flag" [class]="'fi fi-' + flagCountryCode"></div>
          <div>{{ language | uppercase }}</div>
        </div>
        <app-profile-dropdown></app-profile-dropdown>
      </div>
    </div>
  </nav>
</header>
