import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  finalize,
  mergeMap,
  Observable,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

import { nonNullish } from '../../../lib/non-nullish';
import { DialogService } from '../../dialog/service/dialog.service';
import { EventDispatcher } from '../../event-dispatcher/event-dispatcher';
import { SignupVerificationSuccessEvent } from '../../event-dispatcher/events/signup-verification-success-event';
import { LanguageService } from '../../language/service/language.service';
import { NavigationService } from '../../navigation/service/navigation.service';
import { ToastService } from '../../standalone-components/toast/service/toast.service';
import { PhoneVerificationResponse } from '../api/model/phone-verification-response';
import { VerificationResponse } from '../api/model/verification-response';
import { VerificationApi } from '../api/verification.api';
import { ChangePhoneNumberDialogComponent } from '../change-phone-number-dialog/change-phone-number-dialog.component';
import { PhoneVerificationComponent } from '../phone-verification/phone-verification.component';

@Injectable()
export class VerificationService {
  readonly isPhoneVerificationModalOpen$ = new BehaviorSubject<boolean>(false);
  readonly isPhoneVerified$ = new BehaviorSubject<boolean>(false);
  readonly isChangePhoneNumberSubmited$ = new BehaviorSubject<boolean>(false);
  readonly isPhoneCodeSent$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly verificationApi: VerificationApi,
    private readonly navigationService: NavigationService,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly eventDispatcher: EventDispatcher,
    private readonly languageService: LanguageService,
  ) {}

  getVerificationStatus(): Observable<VerificationResponse> {
    return this.verificationApi.verifyUser();
  }

  getRouteToNavigateTo(data: VerificationResponse) {
    if (data.verified) {
      return null;
    }
    return !data.verifiedByPhone
      ? '/verification/phone'
      : '/verification/email';
  }

  verifyPhoneNumber(code: string): Observable<PhoneVerificationResponse> {
    this.isPhoneCodeSent$.next(true);
    return this.verificationApi.verifyPhoneNumber(code).pipe(
      tap((res) => {
        this.isPhoneVerified$.next(res.verified);

        if (res.verified) {
          if (!this.isPhoneVerificationModalOpen$.value) {
            this.navigationService.navigateWithRedirectionPath(
              'patient',
              '/confirmation',
            );
            this.eventDispatcher.dispatch(
              new SignupVerificationSuccessEvent({
                email: res.emailAddress,
                phone: res.phoneNumber,
                userId: res.userId.toString(),
                patientUuid: res.patientUuid,
              }),
            );
          }
        } else {
          this.toastService.show($localize`Incorrect verification code`);
        }
      }),
      finalize(() => this.isPhoneCodeSent$.next(false)),
    );
  }

  sendVerificationCodeToPhone(): Observable<PhoneVerificationResponse> {
    const language = this.languageService.getLanguage();
    return this.verificationApi.sendVerificationCodeToPhone(
      nonNullish(language),
    );
  }

  sendVerificationEmail(): Observable<PhoneVerificationResponse> {
    const language = this.languageService.getLanguage();
    return this.verificationApi.sendVerificationEmail(nonNullish(language));
  }

  changePhoneNumber(phoneNumber: string) {
    this.isChangePhoneNumberSubmited$.next(true);
    return this.verificationApi.changePhoneNumber(phoneNumber).pipe(
      catchError((err: unknown) => {
        this.isChangePhoneNumberSubmited$.next(false);
        return throwError(() => err);
      }),
    );
  }

  displayChangePhoneNumberDialog() {
    const dialogRef = this.dialogService.open(
      ChangePhoneNumberDialogComponent,
      { isConfirmButtonClicked$: this.isChangePhoneNumberSubmited$ },
    );
    dialogRef.dialog.submitted
      .pipe(
        mergeMap((phoneNumber) => this.changePhoneNumber(phoneNumber)),
        mergeMap(() => this.sendVerificationCodeToPhone()),
        tap(() =>
          this.toastService.show($localize`Successfully updated phone number!`),
        ),
        finalize(() => this.isChangePhoneNumberSubmited$.next(false)),
        tap(dialogRef.close),
        takeUntil(dialogRef.closed),
      )
      .subscribe();
  }

  displayPhoneCodeVerificationModal() {
    const dialogRef = this.dialogService.open(
      PhoneVerificationComponent,
      undefined,
      'lg',
    );
    this.isPhoneVerificationModalOpen$.next(true);
    dialogRef.dialog.submitted
      .pipe(
        mergeMap(() =>
          this.getVerificationStatus().pipe(
            tap((res) => this.isPhoneVerified$.next(res.verifiedByPhone)),
          ),
        ),
        tap(() => this.isPhoneVerificationModalOpen$.next(false)),
        tap(dialogRef.close),
        takeUntil(dialogRef.closed),
      )
      .subscribe();
  }

  verifyEmail(): Observable<PhoneVerificationResponse> {
    const params = this.navigationService.getQueryParams();

    return this.verificationApi
      .verifyEmail({
        email: params['email'],
        verificationCode: params['code'],
      })
      .pipe(
        tap((res) => {
          if (res.verified)
            this.navigationService.navigateToWithoutAdditionParams('/account');
        }),
      );
  }
}
