import { AppEvent } from './app-event';

export const SIGNUP_VERIFICATION_SUCCESS_EVENT = 'SIGNUP_VERIFICATION_SUCCESS';

export interface SignupVerificationSuccessEventPayload {
  userId: string | null;
  patientUuid: string | null;
  phone: string | null;
  email: string | null;
}

export class SignupVerificationSuccessEvent extends AppEvent<SignupVerificationSuccessEventPayload> {
  readonly type: string = SIGNUP_VERIFICATION_SUCCESS_EVENT;
  readonly payload: SignupVerificationSuccessEventPayload;

  constructor(signup: SignupVerificationSuccessEventPayload) {
    super();
    this.payload = signup;
  }
}
