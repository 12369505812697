<div
  class="d-flex flex-column justify-content-center align-items-center m-4 min-height"
>
  <h1 class="text-center" i18n>404 - Page not found</h1>
  <h3 class="text-center" i18n>
    Sorry, the page you're looking for does not exist anymore
  </h3>
  <h3 class="text-center mt-2">
    <span i18n>Go to </span>
    <a [href]="lazeoHomepageLink">{{ lazeoHomepageTitle }}</a>
  </h3>
</div>
