import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { map } from 'rxjs';

import { Country } from '../../application-settings/api/model/country';
import { ApplicationSettingsService } from '../../application-settings/service/application-settings.service';
import { LanguageService } from '../../language/service/language.service';
import { MedicalCenterService } from '../../medical-center/service/medical-center.service';

@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectionComponent {
  @Output() modalClose = new EventEmitter<void>();

  currentLocale = this.languageService.getLocale();
  readonly medicalCenterName$ = this.medicalCenterService.shortName$;
  readonly countries$ =
    this.applicationSettingsService.applicationSettings$.pipe(
      map((applicationSettings) =>
        this.mapCountries(applicationSettings.countries),
      ),
    );

  constructor(
    private readonly medicalCenterService: MedicalCenterService,
    private readonly languageService: LanguageService,
    private readonly applicationSettingsService: ApplicationSettingsService,
  ) {}

  private mapCountries(countries: Country[]) {
    return countries.map((country) => ({
      name: this.getLocalizedCountryName(country.countryCode),
      code: this.getAlpha2CountryCode(country.countryCode),
      locales: this.mapLocalesWithEnglishLast(
        country.languages,
        country.countryCode,
      ),
    }));
  }

  private mapLocalesWithEnglishLast(languages: string[], countryCode: string) {
    return [
      ...this.mapLocalesWithoutEnglish(languages, countryCode),
      this.getEnglish(),
    ];
  }

  private mapLocalesWithoutEnglish(languages: string[], countryCode: string) {
    return languages
      .filter((language) => language !== 'en')
      .map((language) => ({
        code: this.getLanguageCode(language, countryCode),
        name: this.getLocalizedLanguageName(language),
      }));
  }

  private getEnglish() {
    return { code: 'en-US', name: this.getLocalizedLanguageName('en') };
  }

  private getLocalizedCountryName(countryCode: string): string {
    return this.languageService.getLocalizedCountryName(countryCode);
  }

  private getLocalizedLanguageName(language: string): string {
    return this.languageService.getLocalizedLanguageName(language);
  }

  private getAlpha2CountryCode(countryCode: string): string {
    return countryCode.toLowerCase().substring(0, 2);
  }

  private getLanguageCode(language: string, countryCode: string): string {
    const availableTranslations = this.languageService.AVAILABLE_TRANSLATIONS;
    const languageCodeForCountry = this.formatLanguageCode(
      language,
      countryCode,
    );
    const languageCodeForLanguage = this.formatLanguageCode(language, language);

    if (availableTranslations.includes(languageCodeForCountry)) {
      return languageCodeForCountry;
    } else if (availableTranslations.includes(languageCodeForLanguage)) {
      return languageCodeForLanguage;
    } else {
      return availableTranslations.filter((translation) =>
        translation.includes(language),
      )[0];
    }
  }

  private formatLanguageCode(prefix: string, suffix: string) {
    return prefix + '-' + suffix.toUpperCase().substring(0, 2);
  }
}
