import { AppEvent } from './app-event';

export const LOGIN_EVENT = 'LOGIN';

export interface LoginEventPayload {
  userId: string | null;
  email: string | null;
  phone: string | null;
  patientUuid: string | null;
  firstName: string | null;
  lastName: string | null;
  gender: string | null;
  city: string | null;
  postalCode: string | null;
  country: string | null;
}

export class LoginEvent extends AppEvent<LoginEventPayload> {
  readonly type: string = LOGIN_EVENT;
  readonly payload: LoginEventPayload;

  constructor(login: LoginEventPayload) {
    super();
    this.payload = login;
  }
}
