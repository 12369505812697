import { AppEvent } from './app-event';

export const BSG_AUTHENTICATED_PAGEVIEW_EVENT = 'BSG_AUTHENTICATED_PAGEVIEW';

export interface BsgPageviewAuthenticatedEventPayload {
  lang: string;
  site: string;
  userId: string;
  patientUuid: string;
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  gender: string;
  city: string;
  postalCode: string;
  country: string;
  path: string;
  mc_name: string;
}

export class BsgPageviewAuthenticatedEvent extends AppEvent<BsgPageviewAuthenticatedEventPayload> {
  readonly type: string = BSG_AUTHENTICATED_PAGEVIEW_EVENT;
  readonly payload: BsgPageviewAuthenticatedEventPayload;

  constructor(bsgPageview: BsgPageviewAuthenticatedEventPayload) {
    super();
    this.payload = bsgPageview;
  }
}
