import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ConfigService } from './config.service';

@Injectable()
export class BaseApi {
  protected readonly baseUrl = ConfigService.apiUrl();
  protected readonly basePortalUrl = ConfigService.apiPortalUrl();

  constructor(protected readonly httpClient: HttpClient) {}
}
