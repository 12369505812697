import { NgModule } from '@angular/core';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';

import { ConfigService } from '../config.service';
import { AnalyticsService } from './analytics.service';
import { AnalyticsListener } from './analytics-listener';

@NgModule({
  providers: [AnalyticsService, AnalyticsListener],
  imports: [
    GoogleTagManagerModule.forRoot({
      id: ConfigService.gtmId(),
    }),
  ],
})
export class AnalyticsModule {}
