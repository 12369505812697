<app-header></app-header>
<main class="pt-4">
  <router-outlet></router-outlet>
  <app-toast></app-toast>
</main>
<footer class="mt-auto py-3">
  <p class="text-center text-muted">
    <img alt="lazeo" [src]="logoDataUrl" width="28" height="28" />
  </p>
  <p class="text-center text-muted mb-0" i18n>
    © Copyright@2023 {{ brandName }}
  </p>
  <p class="text-center text-muted" i18n>All rights reserved</p>
</footer>
