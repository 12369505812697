import { AppEvent } from './app-event';

export const SIGNUP_VERIFICATION_EVENT = 'SIGNUP_VERIFICATION';

export interface SignupVerificationEventPayload {
  phone: string;
  email: string;
  userId: string | null;
  patientUuid: string | null;
}

export class SignupVerificationEvent extends AppEvent<SignupVerificationEventPayload> {
  readonly type: string = SIGNUP_VERIFICATION_EVENT;
  readonly payload: SignupVerificationEventPayload;

  constructor(signup: SignupVerificationEventPayload) {
    super();
    this.payload = signup;
  }
}
