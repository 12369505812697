import { AppEvent } from './app-event';

export const VALIDATE_TIMESLOT_EVENT = 'VALIDATE_TIMESLOT';
export interface ValidateTimeslotEventPayload {
  categoryId: number;
  categoryName: string;
  motiveId: number;
  motiveName: string;
  medicalCenterName: string;
}

export class ValidateTimeslotEvent extends AppEvent<ValidateTimeslotEventPayload> {
  readonly type: string = VALIDATE_TIMESLOT_EVENT;
  readonly payload: ValidateTimeslotEventPayload;

  constructor(appointmentInformation: ValidateTimeslotEventPayload) {
    super();
    this.payload = appointmentInformation;
  }
}
