import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import {
  faPowerOff,
  faSignIn,
  faUser,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';

import { AuthenticationService } from '../../authentication/service/authentication.service';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDropdownComponent {
  readonly faUser = faUser;
  readonly faPowerOff = faPowerOff;
  readonly faUserPlus = faUserPlus;
  readonly faSignIn = faSignIn;
  readonly displayName$ = this.authenticationService.displayName$;
  readonly smallDeviceBreakpoint = 320;
  readonly mediumDeviceBreakpoint = 425;
  screenWidth: number = window.innerWidth;

  constructor(private readonly authenticationService: AuthenticationService) {}

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.screenWidth = window.innerWidth;
  }

  logOut(): void {
    this.authenticationService.logout('/locations', true).subscribe();
  }
}
