import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { BaseApi } from '../../base.api';
import { ApplicationSettingsNotFoundError } from './error/application-settings-not-found-error';
import { errorCodes } from './error/error-codes';
import { ApplicationSettings } from './model/application-settings';

@Injectable()
export class ApplicationSettingsApi extends BaseApi {
  getApplicationSettings(): Observable<ApplicationSettings> {
    const url = this.baseUrl + `/application-settings-public`;

    return this.httpClient.get<ApplicationSettings>(url).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          const errorCode = err.error.code;

          if (errorCode === errorCodes.APPLICATION_SETTINGS_NOT_FOUND) {
            return throwError(() => new ApplicationSettingsNotFoundError());
          }
        }

        return throwError(() => err);
      }),
    );
  }
}
