import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class Sha256Service {
  readonly email$ = new BehaviorSubject<string | null>(null);
  readonly phone$ = new BehaviorSubject<string | null>(null);

  userEmail$ = this.email$.asObservable();
  userPhone$ = this.phone$.asObservable();

  constructor() {
    this.email$.next(window.useremail ?? null);
    this.phone$.next(window.userphone ?? null);
  }

  calculateSHA256Hash(input: string): Observable<string> {
    return from(
      crypto.subtle.digest('SHA-256', new TextEncoder().encode(input)),
    ).pipe(
      switchMap((buffer) => {
        const hashArray = Array.from(new Uint8Array(buffer));
        const hashHex = hashArray
          .map((byte) => byte.toString(16).padStart(2, '0'))
          .join('');
        return of(hashHex);
      }),
    );
  }

  async calculateSHA256HashPromiseObject(input: {
    [key: string]: string | undefined | null;
  }): Promise<{ [key: string]: string }> {
    const hashedObject: { [key: string]: string } = {};

    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        const value = input[key];
        if (value) {
          const buffer = await crypto.subtle.digest(
            'SHA-256',
            new TextEncoder().encode(value),
          );
          const hashArray = Array.from(new Uint8Array(buffer));
          const hashHex = hashArray
            .map((byte) => byte.toString(16).padStart(2, '0'))
            .join('');
          hashedObject[key] = hashHex;
        } else {
          hashedObject[key] = '';
        }
      }
    }

    return hashedObject;
  }

  setEmailAndPhoneVariables(
    email: string | undefined,
    phone: string | undefined,
  ) {
    if (email !== undefined && phone !== undefined) {
      return combineLatest([
        this.calculateSHA256Hash(email),
        this.calculateSHA256Hash(phone),
      ]).pipe(
        tap(([email, phone]) => {
          window.useremail = email;
          window.userphone = phone;
          this.email$.next(email);
          this.phone$.next(phone);
        }),
      );
    }

    return of(['', '']);
  }
}
