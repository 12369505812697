import { AppEvent } from './app-event';

export const SIGNUP_EVENT = 'SIGNUP';

export interface SignupEventPayload {
  firstName: string;
  lastName: string;
  gender: string;
  city: string;
  postalCode: string;
  country: string;
  userId: string | null;
  patientUuid: string | null;
  phone: string | null;
  email: string | null;
}

export class SignupEvent extends AppEvent<SignupEventPayload> {
  readonly type: string = SIGNUP_EVENT;
  readonly payload: SignupEventPayload;

  constructor(signup: SignupEventPayload) {
    super();
    this.payload = signup;
  }
}
