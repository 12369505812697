import { Injectable } from '@angular/core';

import { AppEvent } from './events/app-event';

type EventListener = <T extends AppEvent<unknown>>(event: T) => void;

@Injectable({ providedIn: 'root' })
export class EventDispatcher {
  private readonly listeners: Map<string, EventListener[]> = new Map();

  dispatch(event: AppEvent<unknown>) {
    this.listeners.get(event.type)?.forEach((l) => l(event));
  }

  subscribe(eventType: string, listener: EventListener) {
    this.listeners.set(eventType, [
      ...(this.listeners.get(eventType) ?? []),
      listener,
    ]);
  }
}
