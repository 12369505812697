<div class="app-label mb-3 form-floating">
  <form>
    <ng-container>
      <intl-tel-input
        cssClass="form-control form-input"
        [onlyLocalized]="false"
        [required]="true"
        [options]="{
          preferredCountries: preferredCountryCodes,
          onlyCountries: allowedCountries,
          initialCountry: defaultCountryCode
        }"
        (E164PhoneNumberChange)="changePhoneNumber($event)"
        data-testid="phoneNumberInput"
      ></intl-tel-input>
      <div class="form-help" i18n *ngIf="value || !shouldShowErrors">
        {{ message }}
      </div>
      <div i18n *ngIf="!value && shouldShowErrors" class="error">
        Please enter valid phone
      </div>
    </ng-container>
  </form>
</div>
