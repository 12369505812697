import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  first,
  map,
  mergeMap,
  Observable,
  ReplaySubject,
  shareReplay,
  tap,
  throwError,
} from 'rxjs';

import { nonNullish } from '../../../lib/non-nullish';
import { MedicalCenterLocationsRequest } from '../../store-locator/model/medical-center-locations-request';
import { MedicalCenterNotFoundError } from '../api/error/medical-center-not-found-error';
import { MedicalCenterApi } from '../api/medical-center.api';
import { MedicalCenterLocationResponse } from '../api/model/medical-center-location-response';
import { MedicalCenter } from '../model/medical-center';

const FRANCE_COUNTRY_CODE = 'fr';

@Injectable({
  providedIn: 'root',
})
export class MedicalCenterService {
  public readonly shortName$ = new ReplaySubject<string>(1);
  public readonly countryCode$ = new BehaviorSubject<string>(
    FRANCE_COUNTRY_CODE,
  );
  public readonly medicalCenter$: Observable<MedicalCenter> =
    this.shortName$.pipe(
      mergeMap((medicalCenterName) =>
        this.medicalCenterApi.getMedicalCenterByShortName(
          nonNullish(medicalCenterName),
        ),
      ),
      tap((mc) => {
        const countryCode =
          !!mc.address && mc.address.countryCode !== null
            ? mc.address.countryCode.slice(0, 2).toLowerCase()
            : FRANCE_COUNTRY_CODE;
        this.countryCode$.next(countryCode);
      }),
      catchError((err: unknown) => {
        if (err instanceof MedicalCenterNotFoundError) {
          this.router.navigate(['/page-not-found']);
        }
        return throwError(() => err);
      }),
      shareReplay({ bufferSize: 1, refCount: false }),
    );
  public readonly medicalCenterId$ = this.medicalCenter$.pipe(
    map((mc) => mc.id),
  );
  private readonly queryParams = new URLSearchParams(window.location.search);
  private readonly idInQuery =
    this.queryParams.get('id') ?? this.queryParams.get('medicalCenterId');
  private readonly mcInQuery = this.queryParams.get('mc');

  constructor(
    private readonly router: Router,
    private readonly medicalCenterApi: MedicalCenterApi,
  ) {
    if (this.mcInQuery) {
      this.shortName$.next(this.mcInQuery);
    }
  }

  public doLegacyRoutingIfNeeded(): void {
    const legacyRoutingUsed = this.idInQuery && !this.mcInQuery;
    if (legacyRoutingUsed) {
      console.error('Legacy medical center routing used!');
      this.medicalCenterApi
        .getMedicalCenterById(parseInt(nonNullish(this.idInQuery), 10))
        .pipe(
          tap((mc) => this.shortName$.next(nonNullish(mc.shortName))),
          catchError((err: unknown) => {
            if (err instanceof MedicalCenterNotFoundError) {
              this.router.navigate(['/page-not-found']);
            }
            return throwError(() => err);
          }),
          first(),
        )
        .subscribe();
    }
  }

  public getMedicalCenterLocations(
    medicalCenterLocationsRequest: MedicalCenterLocationsRequest,
  ): Observable<MedicalCenterLocationResponse[]> {
    return this.medicalCenterApi
      .getMedicalCenterLocations(medicalCenterLocationsRequest)
      .pipe(
        catchError((err: unknown) => throwError(() => err)),
        first(),
      );
  }
}
