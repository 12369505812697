import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn } from '@angular/router';

import { NavigationService } from '../../navigation/service/navigation.service';

type Param = string | null | undefined;

@Injectable({ providedIn: 'root' })
export class MedicalCenterGuard {
  constructor(private readonly navigationService: NavigationService) {}

  canActivate(
    medicalCenterName: Param,
    id: Param,
    medicalCenterId: Param,
    motiveId: Param,
  ): boolean {
    if (
      this.medicalCenterNameProvided(medicalCenterName) ||
      this.medicalCenterIdProvided(id ?? medicalCenterId)
    ) {
      return true;
    } else {
      this.navigationService.navigateToStoreLocator(motiveId);
      return false;
    }
  }

  private medicalCenterNameProvided(medicalCenterName: Param) {
    return /^[\sA-Za-z0-9'-]+$/.test(medicalCenterName ?? '');
  }

  private medicalCenterIdProvided(medicalCenterId: Param) {
    return !Number.isNaN(parseInt(medicalCenterId ?? '', 10));
  }
}

export const canActivate: CanActivateFn = (route: ActivatedRouteSnapshot) =>
  inject(MedicalCenterGuard).canActivate(
    route.queryParams['mc'],
    route.queryParams['id'],
    route.queryParams['medicalCenterId'],
    route.queryParams['motiveId'],
  );
