<div class="toast-container position-fixed top-0 end-0 p-3 z-index">
  <ngb-toast
    *ngFor="let toast of toastService.toasts$ | async"
    [autohide]="true"
    [delay]="5000"
    (hidden)="toastService.remove(toast)"
    [animation]="true"
    class="bg-white"
  >
    <span class="toast-message">{{ toast }}</span>
  </ngb-toast>
</div>
