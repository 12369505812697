import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[ngVar]',
  standalone: true,
})
export class NgVarDirective<T> {
  @Input()
  set ngVar(context: T) {
    this.context.$implicit = this.context.ngVar = context;
    this.updateView();
  }

  public context: { $implicit?: T; ngVar?: T } = {};

  constructor(
    private readonly vcRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>,
  ) {}

  public updateView(): void {
    this.vcRef.clear();
    this.vcRef.createEmbeddedView(this.templateRef, this.context);
  }
}
