import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { nonNullish } from '../../../lib/non-nullish';

@Component({
  selector: 'app-forgot-password-dialog',
  templateUrl: './forgot-password-dialog.component.html',
  styleUrls: ['./forgot-password-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordDialogComponent {
  @Output()
  submitted = new EventEmitter<string>();
  @Input({ required: true }) isSendNewPasswordButtonClicked$ =
    new BehaviorSubject<boolean>(false);

  readonly email = new FormControl('', {
    validators: [Validators.required, Validators.email],
  });

  submitClicked(e: SubmitEvent) {
    e.preventDefault();
    this.submitted.emit(nonNullish(this.email.value));
  }
}
