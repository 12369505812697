import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApi } from '../../base.api';
import { EmailVerificationRequest } from './model/email-verification-request';
import { PhoneVerificationResponse } from './model/phone-verification-response';
import { VerificationResponse } from './model/verification-response';

@Injectable()
export class VerificationApi extends BaseApi {
  verifyUser(): Observable<VerificationResponse> {
    return this.httpClient.get<VerificationResponse>(
      this.baseUrl + '/booking/verification',
    );
  }

  verifyPhoneNumber(code: string): Observable<PhoneVerificationResponse> {
    return this.httpClient.post<PhoneVerificationResponse>(
      this.baseUrl + `/booking/verification/phone/${code}`,
      null,
    );
  }

  sendVerificationCodeToPhone(
    language: string,
  ): Observable<PhoneVerificationResponse> {
    return this.httpClient.post<PhoneVerificationResponse>(
      this.baseUrl + `/booking/verification/phone?language=${language}`,
      null,
    );
  }

  sendVerificationEmail(
    language: string,
  ): Observable<PhoneVerificationResponse> {
    return this.httpClient.post<PhoneVerificationResponse>(
      this.baseUrl + `/booking/verification/email?language=${language}`,
      null,
    );
  }

  changePhoneNumber(phoneNumber: string): Observable<void> {
    return this.httpClient.put<void>(
      this.baseUrl + '/booking/public/auth/change-phone-number',
      { phoneNumber: phoneNumber },
    );
  }

  verifyEmail(
    verificationData: EmailVerificationRequest,
  ): Observable<PhoneVerificationResponse> {
    return this.httpClient.post<PhoneVerificationResponse>(
      this.baseUrl + '/booking/verification/email/verify',
      verificationData,
    );
  }
}
