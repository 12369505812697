import { AppEvent } from './app-event';

export const BSG_UNAUTHENTICATED_PAGEVIEW_EVENT =
  'BSG_UNAUTHENTICATED_PAGEVIEW';

export interface BsgPageviewUnauthenticatedEventPayload {
  path: string;
  mc_name: string;
}

export class BsgPageviewUnauthenticatedEvent extends AppEvent<BsgPageviewUnauthenticatedEventPayload> {
  readonly type: string = BSG_UNAUTHENTICATED_PAGEVIEW_EVENT;
  readonly payload: BsgPageviewUnauthenticatedEventPayload;

  constructor(bsgPageview: BsgPageviewUnauthenticatedEventPayload) {
    super();
    this.payload = bsgPageview;
  }
}
