import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { BehaviorSubject, finalize, first, tap } from 'rxjs';

import { nonNullish } from '../../../lib/non-nullish';
import { ToastService } from '../../standalone-components/toast/service/toast.service';
import { VerificationService } from '../service/verification.service';

@Component({
  templateUrl: './phone-verification.component.html',
  styleUrls: ['./phone-verification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneVerificationComponent implements OnInit {
  verificationCode: string | null = null;
  isConfirmButtonDisabled$ = new BehaviorSubject<boolean>(true);
  isConfirmButtonClicked$ = this.verificationService.isPhoneCodeSent$;
  @Output() submitted = new EventEmitter<string>();

  constructor(
    private readonly verificationService: VerificationService,
    readonly toastService: ToastService,
  ) {}

  ngOnInit(): void {
    this.sendVerificationCodeToPhone().subscribe();
  }

  onCodeCompleted(code: string) {
    this.verificationCode = code;
  }

  onCodeChanged(code: string) {
    this.isConfirmButtonDisabled$.next(code.length !== 4);
  }

  verifyPhone() {
    this.isConfirmButtonDisabled$.next(true);
    this.verificationService
      .verifyPhoneNumber(nonNullish(this.verificationCode))
      .pipe(
        tap(() => this.submitted.emit()),
        finalize(() => this.isConfirmButtonDisabled$.next(false)),
      )
      .subscribe();
  }

  sendVerificationCodeToPhone() {
    return this.verificationService.sendVerificationCodeToPhone().pipe(first());
  }

  receiveAnotherCode() {
    this.sendVerificationCodeToPhone()
      .pipe(
        tap(() =>
          this.toastService.show(
            $localize`You should receive SMS code on your mobile phone`,
          ),
        ),
      )
      .subscribe();
  }

  changePhoneNumber() {
    this.verificationService.displayChangePhoneNumberDialog();
  }
}
