import { ChangeDetectionStrategy, Component } from '@angular/core';

import { ConfigService } from '../../config.service';

@Component({
  standalone: true,
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  readonly lazeoHomepageLink = ConfigService.lazeoWebsiteLink();
  readonly lazeoHomepageTitle = ConfigService.lazeoWebsiteTitle();
}
