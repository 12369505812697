<div *ngVar="displayName$ | async as user" class="d-flex ms-3">
  <div class="dropdown d-inline-block" ngbDropdown>
    <button
      class="d-flex align-items-center btn lazeo-link no-dropdown-arrow padding-reset"
      type="button"
      ngbDropdownToggle
      id="profileDropdownMenu"
    >
      <fa-icon class="dark-orange" [icon]="faUser" />
      <span *ngIf="screenWidth < smallDeviceBreakpoint"></span>
      <span class="text-font-light ms-2">
        <span
          *ngIf="
            user &&
            screenWidth <= mediumDeviceBreakpoint &&
            screenWidth >= smallDeviceBreakpoint
          "
          >{{ user.split(" ")[0] }}</span
        >
        <span *ngIf="screenWidth > mediumDeviceBreakpoint">{{ user }}</span>
      </span>
    </button>
    <div
      ngbDropdownMenu
      aria-labelledby="profileDropdownMenu"
      class="dropdown-end"
    >
      <ng-container *ngIf="user; else noUser">
        <a
          ngbDropdownItem
          class="d-flex justify-content-between align-items-center dropdown-item"
          routerLink="/account"
          queryParamsHandling="merge"
        >
          <span class="text-font-light" i18n> Profile </span>
          <fa-icon class="dark-orange me-2" [icon]="faUser" />
        </a>
        <a
          ngbDropdownItem
          class="d-flex justify-content-between align-items-center dropdown-item"
          (click)="logOut()"
        >
          <span class="text-font-light" i18n> Logout </span>
          <fa-icon class="dark-orange me-2" [icon]="faPowerOff" />
        </a>
      </ng-container>

      <ng-template #noUser>
        <a
          ngbDropdownItem
          class="d-flex justify-content-between align-items-center dropdown-item"
          routerLink="/auth/login"
          queryParamsHandling="merge"
        >
          <span class="text-font-light" i18n> Login </span>
          <fa-icon class="dark-orange me-2" [icon]="faSignIn" />
        </a>
        <a
          ngbDropdownItem
          class="d-none invisible d-flex justify-content-between align-items-center dropdown-item"
          routerLink="/auth/register"
          queryParamsHandling="merge"
        >
          <span class="text-font-light" i18n> Register </span>
          <fa-icon class="dark-orange me-2" [icon]="faUserPlus" />
        </a>
      </ng-template>
    </div>
  </div>
</div>
