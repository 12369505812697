import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-change-phone-number-dialog',
  templateUrl: './change-phone-number-dialog.component.html',
  styleUrls: ['./change-phone-number-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePhoneNumberDialogComponent {
  phoneNumber = '';
  isFormSubmitted = false;
  @Output() submitted = new EventEmitter<string>();
  @Input({ required: true }) isConfirmButtonClicked$ =
    new BehaviorSubject<boolean>(false);

  setPhoneNumber(number: string) {
    this.phoneNumber = number;
  }

  changePhoneNumber() {
    this.isFormSubmitted = true;
    if (this.phoneNumber) this.submitted.emit(this.phoneNumber);
  }
}
