import { Injectable } from '@angular/core';
import { Router, UrlCreationOptions, UrlTree } from '@angular/router';

import { ConfigService } from '../../config.service';

const prefix = ConfigService.getMaintenanceModeActivePath().slice(1) + '/';

@Injectable()
export class MaintenanceRouter extends Router {
  constructor() {
    super();
  }

  override createUrlTree(
    commands: unknown[],
    navigationExtras?: UrlCreationOptions,
  ): UrlTree {
    commands = commands.map((command) => prefix + `${command}`);
    return super.createUrlTree(commands, navigationExtras);
  }
}
