import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { Language } from '../model/language';

@Injectable({
  providedIn: 'root',
})
@Pipe({ name: 'localeToLanguage' })
export class LocaleToLanguagePipe implements PipeTransform {
  transform(value: string): Language {
    const language = value.substring(0, 2);
    if (
      language !== 'en' &&
      language !== 'fr' &&
      language !== 'nl' &&
      language !== 'de'
    ) {
      throw new Error('Invalid locale: ' + value);
    }
    return language;
  }
}
