import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first, tap } from 'rxjs';

import { MedicalCenterService } from '../../medical-center/service/medical-center.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private static readonly REDIRECTION_PATH_KEY = 'rt';
  private readonly medicalCenterName$ = this.medicalCenterService.shortName$;

  constructor(
    private readonly router: Router,
    private readonly medicalCenterService: MedicalCenterService,
    private readonly route: ActivatedRoute,
  ) {}

  navigateTo(path: string, params: Record<string, string> = {}) {
    this.medicalCenterName$
      .pipe(
        first(),
        tap((shortName) => {
          this.router.navigate([path], {
            queryParams: {
              mc: shortName,
              ...params,
            },
            queryParamsHandling: 'merge',
          });
        }),
      )
      .subscribe();
  }

  navigateToWithoutMedicalCenter(
    path: string,
    params: Record<string, string> = {},
  ): void {
    this.router.navigate([path], {
      queryParams: {
        ...params,
      },
      queryParamsHandling: 'merge',
    });
  }

  navigateToHomePageWithMedicalCenter(medicalCenterShortName: string): void {
    this.medicalCenterService.shortName$.next(medicalCenterShortName);
    this.navigateToHomePage();
  }

  navigateWithRedirectionPath(path: string, redirectTo: string) {
    return this.navigateTo(path, {
      [NavigationService.REDIRECTION_PATH_KEY]: redirectTo,
    });
  }

  navigateWithRedirectionPathAndWithoutMedicalCenter(
    path: string,
    redirectTo: string,
  ) {
    return this.navigateToWithoutMedicalCenter(path, {
      [NavigationService.REDIRECTION_PATH_KEY]: redirectTo,
    });
  }

  navigateToRedirectionPath() {
    return this.navigateToWithoutAdditionParams(
      this.getQueryParams()[NavigationService.REDIRECTION_PATH_KEY],
    );
  }

  navigateToWithoutAdditionParams(path: string) {
    this.medicalCenterName$
      .pipe(
        first(),
        tap((shortName) => {
          this.router.navigate([path], {
            queryParams: { mc: shortName },
          });
        }),
      )
      .subscribe();
  }

  navigateToHomePage() {
    this.navigateTo('');
  }

  navigateToStoreLocator(motiveId: string | null | undefined) {
    this.router.navigate(['/locations'], {
      queryParams: { motiveId: motiveId },
      queryParamsHandling: 'merge',
    });
  }

  navigateTo404Page() {
    return this.navigateWithoutQueryParams('/page-not-found');
  }

  getQueryParams() {
    return this.route.snapshot.queryParams;
  }

  private navigateWithoutQueryParams(path: string) {
    this.router.navigate([path]);
  }
}
