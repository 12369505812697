import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  EMPTY,
  finalize,
  mergeMap,
  Observable,
  ReplaySubject,
  startWith,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

import { Sha256Service } from '../../analytics/sha-256.service';
import { Patient } from '../../appointment-creation/patient/api/model/patient';
import { DialogService } from '../../dialog/service/dialog.service';
import { EventDispatcher } from '../../event-dispatcher/event-dispatcher';
import { LoginEvent } from '../../event-dispatcher/events/login-event';
import { LogoutEvent } from '../../event-dispatcher/events/logout-event';
import { SignupVerificationEvent } from '../../event-dispatcher/events/signup-verification-event';
import { LanguageService } from '../../language/service/language.service';
import { NavigationService } from '../../navigation/service/navigation.service';
import { ToastService } from '../../standalone-components/toast/service/toast.service';
import { AuthenticationApi } from '../api/authentication.api';
import { EmailOrPasswordNotCorrect } from '../api/error/email-or-password-not-correct';
import { ExpiredPasswordResetCodeError } from '../api/error/expired-password-reset-code-error';
import { ExternalUserNotFoundError } from '../api/error/external-user-not-found-error';
import { InvalidPasswordResetCodeError } from '../api/error/invalid-password-reset-code-error';
import { WaitMinuteBeforeNextLogin } from '../api/error/wait-minute-before-next-login';
import { LoginRequest } from '../api/model/login-request';
import { RegistrationRequest } from '../api/model/registration-request';
import { RegistrationResponse } from '../api/model/registration-response';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  readonly isLoginDataSubmited$ = new BehaviorSubject<boolean>(false);
  readonly isForgotPasswordDataSubmited$ = new BehaviorSubject<boolean>(false);
  public patientData$ = new BehaviorSubject<Patient | null>(null);
  readonly userId$ = new BehaviorSubject<number | null>(null);
  private readonly user$ = new ReplaySubject<string | null>(1);
  public displayName$: Observable<string | null> = this.user$.pipe(
    startWith(localStorage.getItem('displayName')),
  );

  constructor(
    private readonly authenticationApi: AuthenticationApi,
    private readonly dialogService: DialogService,
    private readonly toastService: ToastService,
    private readonly navigationService: NavigationService,
    private readonly eventDispatcher: EventDispatcher,
    private readonly sha256Service: Sha256Service,
    private readonly languageService: LanguageService,
  ) {}

  submitLogin(loginRequest: LoginRequest): Observable<RegistrationResponse> {
    this.isLoginDataSubmited$.next(true);
    return this.authenticationApi.submitLogin(loginRequest).pipe(
      tap(this.onSuccessfulAuthentication.bind(this)),
      tap((res) => this.userId$.next(res.userId)),
      tap((res) =>
        this.eventDispatcher.dispatch(
          new LoginEvent({
            userId: res.userId.toString(),
            email: res.username,
            phone: res.phoneNumber,
            patientUuid: res.patientUuid,
            firstName: res.firstName ? res.firstName : null,
            lastName: res.lastname ? res.lastname : null,
            gender: res.gender,
            city: res.city,
            postalCode: res.postalCode,
            country: res.country,
          }),
        ),
      ),
      catchError((err: unknown) => {
        this.isLoginDataSubmited$.next(false);
        if (err instanceof EmailOrPasswordNotCorrect) {
          this.toastService.show($localize`Wrong email or password`);
          return EMPTY;
        } else if (err instanceof WaitMinuteBeforeNextLogin) {
          this.toastService.show($localize`Wait a minute before next try`);
          return EMPTY;
        }

        return throwError(() => err);
      }),
    );
  }

  openForgotPasswordDialog() {
    const dialogRef = this.dialogService.open(ForgotPasswordDialogComponent, {
      isSendNewPasswordButtonClicked$: this.isForgotPasswordDataSubmited$,
    });
    dialogRef.dialog.submitted
      .pipe(
        mergeMap((email) => this.sendPasswordReset(email)),
        catchError((err: unknown) => {
          this.isForgotPasswordDataSubmited$.next(false);
          if (err instanceof ExternalUserNotFoundError) {
            this.toastService.show($localize`User not found`);
            return EMPTY;
          }

          return throwError(() => err);
        }),
        tap(() =>
          this.toastService.show(
            $localize`Email has been sent, please check for new password`,
          ),
        ),
        tap(dialogRef.close),
        finalize(dialogRef.close),
        takeUntil(dialogRef.closed),
      )
      .subscribe();
  }

  submitRegistration(
    registrationRequest: RegistrationRequest,
  ): Observable<RegistrationResponse> {
    return this.authenticationApi.submitRegistration(registrationRequest).pipe(
      tap((response) =>
        this.eventDispatcher.dispatch(
          new SignupVerificationEvent({
            userId: response.userId.toString(),
            patientUuid: response.patientUuid,
            email: registrationRequest.email,
            phone: registrationRequest.phoneNumber,
          }),
        ),
      ),
      tap(this.onSuccessfulAuthentication.bind(this)),
    );
  }

  setDisplayName(firstName: string | null, lastname: string | null) {
    const displayName = `${firstName} ${lastname}`;
    localStorage.setItem('displayName', displayName);
    this.user$.next(displayName);
  }

  logout(
    navigationPath: string,
    withoutMedicalCenter?: boolean,
  ): Observable<void> {
    return this.authenticationApi.logoutOnBackend().pipe(
      tap(() => {
        this.eventDispatcher.dispatch(new LogoutEvent());
        localStorage.clear();
        this.user$.next(null);
        window.useremail = null;
        window.userphone = null;
        this.sha256Service.email$.next(null);
        this.sha256Service.phone$.next(null);
        this.patientData$.next(null);
      }),
      tap(() => this.navigate(navigationPath, withoutMedicalCenter)),
    );
  }

  changePassword(email: string, newPassword: string, code: string) {
    return this.authenticationApi.changePassword(email, newPassword, code).pipe(
      switchMap(() => this.logout('/auth/login', true)),
      catchError((err: unknown) => {
        if (err instanceof ExternalUserNotFoundError) {
          this.toastService.show($localize`User not found`);
          return EMPTY;
        }
        if (err instanceof ExpiredPasswordResetCodeError) {
          this.toastService.show($localize`Verification code expired`);
          return EMPTY;
        }
        if (err instanceof InvalidPasswordResetCodeError) {
          this.toastService.show($localize`Invalid verification code`);
          return EMPTY;
        }

        return throwError(() => err);
      }),
    );
  }

  private sendPasswordReset(email: string): Observable<void> {
    this.isForgotPasswordDataSubmited$.next(true);

    const language = this.languageService.getLanguage();
    return this.authenticationApi
      .sendPasswordReset(email, language)
      .pipe(finalize(() => this.isForgotPasswordDataSubmited$.next(false)));
  }

  private onSuccessfulAuthentication(res: RegistrationResponse) {
    localStorage.setItem('token', res.token);
    if (res.patient) {
      this.setDisplayName(res.firstName, res.lastname);
    }
  }

  private navigate(
    navigationPath: string,
    withoutMedicalCenter?: boolean,
  ): void {
    if (withoutMedicalCenter) {
      this.navigationService.navigateToWithoutMedicalCenter(navigationPath);
    } else {
      this.navigationService.navigateTo(navigationPath);
    }
  }
}
