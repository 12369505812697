import { NgOptimizedImage } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { AnalyticsModule } from './analytics/analytics.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationSettingsApi } from './application-settings/api/application-settings.api';
import { ApplicationSettingsService } from './application-settings/service/application-settings.service';
import { PatientApi } from './appointment-creation/patient/api/patient.api';
import { PatientService } from './appointment-creation/patient/service/patient.service';
import { AuthenticationInterceptor } from './authentication/interceptor/authentication.interceptor';
import { GlobalErrorHandler } from './global-error-handler';
import { HeaderModule } from './header/header.module';
import { PageNotFoundComponent } from './standalone-components/page-not-found/page-not-found.component';
import { ToastComponent } from './standalone-components/toast/toast.component';
import { VerificationApi } from './verification/api/verification.api';
import { VerificationService } from './verification/service/verification.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbDropdownModule,
    HttpClientModule,
    HeaderModule,
    NgOptimizedImage,
    ToastComponent,
    PageNotFoundComponent,
    AnalyticsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    VerificationService,
    VerificationApi,
    PatientService,
    PatientApi,
    ApplicationSettingsApi,
    ApplicationSettingsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
