import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { BaseApi } from '../../base.api';
import { MedicalCenterLocationsRequest } from '../../store-locator/model/medical-center-locations-request';
import { errorCodes } from './error/error-codes';
import { MedicalCenterNotFoundError } from './error/medical-center-not-found-error';
import { MedicalCenterLocationResponse } from './model/medical-center-location-response';
import { MedicalCenterResponse } from './model/medical-center-response';

@Injectable({
  providedIn: 'root',
})
export class MedicalCenterApi extends BaseApi {
  private readonly notFoundErrorCodes = [
    errorCodes.MEDICAL_CENTER_NOT_FOUND,
    errorCodes.RESOURCE_NOT_FOUND,
  ];

  getMedicalCenterById(
    medicalCenterId: number,
  ): Observable<MedicalCenterResponse> {
    const url =
      this.baseUrl + `/booking/public/medical-centers/${medicalCenterId}`;

    return this.httpClient
      .get<MedicalCenterResponse>(url)
      .pipe(catchError(this.mapIfNotFoundErrorCode.bind(this)));
  }

  getMedicalCenterLocations(
    medicalCenterLocationsRequest: MedicalCenterLocationsRequest,
  ): Observable<MedicalCenterLocationResponse[]> {
    const url = this.baseUrl + '/booking/public/medical-centers/locations';

    return this.httpClient
      .post<MedicalCenterLocationResponse[]>(url, medicalCenterLocationsRequest)
      .pipe(catchError(this.mapIfNotFoundErrorCode.bind(this)));
  }

  getMedicalCenterByShortName(
    shortName: string,
  ): Observable<MedicalCenterResponse> {
    const url = this.baseUrl + `/booking/public/medical-centers`;

    return this.httpClient
      .get<MedicalCenterResponse>(url, {
        params: { shortName },
      })
      .pipe(catchError(this.mapIfNotFoundErrorCode.bind(this)));
  }

  private readonly mapIfNotFoundErrorCode = (err: unknown) => {
    if (
      err instanceof HttpErrorResponse &&
      this.notFoundErrorCodes.includes(err.error.code)
    ) {
      return throwError(() => new MedicalCenterNotFoundError());
    }
    return throwError(() => err);
  };
}
