<div
  class="d-flex flex-column justify-content-center align-items-center w-100 h-100"
>
  <h1 tabindex="0" i18n>Select your region</h1>
  <div class="mt-5">
    <div class="mb-4" *ngFor="let country of countries$ | async">
      <div class="d-flex align-items-center mb-4">
        <div class="me-3 country-flag" [class]="'fi fi-' + country.code"></div>
        <div class="country-name">{{ country.name }}</div>
      </div>
      <div class="d-flex">
        <ng-container *ngFor="let locale of country.locales">
          <a
            *ngIf="locale.code === currentLocale; else differentLocale"
            (click)="modalClose.emit()"
            class="me-3 no-decoration cursor-pointer"
            >{{ locale.name }}</a
          >
          <ng-template #differentLocale>
            <a
              href="/{{ locale.code }}?mc={{ medicalCenterName$ | async }}"
              class="me-3 no-decoration"
              >{{ locale.name }}</a
            >
          </ng-template>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<button
  tabindex="0"
  type="button"
  class="btn-close position-absolute top-0 end-0 m-3"
  aria-label="Close"
  (click)="modalClose.emit()"
></button>
