<div class="dialog-wrapper">
  <div class="row">
    <div class="text-center fw-bold h5 mb-3" i18n>
      Please enter the correct phone number
    </div>
  </div>
  <div
    class="d-flex flex-column align-items-center justify-content-center flex-wrap w-100"
  >
    <div class="col-md-8">
      <app-phone-input
        (valueChanged)="setPhoneNumber($event)"
        [shouldShowErrors]="isFormSubmitted"
      ></app-phone-input>
    </div>

    <button
      class="btn secondary mt-4 w-50"
      type="submit"
      (click)="changePhoneNumber()"
      data-testid="changePhoneNumberButton"
      [disabled]="isConfirmButtonClicked$ | async"
    >
      <span
        *ngIf="(isConfirmButtonClicked$ | async) === false; else loaderTemplate"
        i18n
        >Confirm</span
      >
      <ng-template #loaderTemplate>
        <app-loader
          iconClasses="fa-1x"
          class="d-flex justify-content-center align-items-center"
        ></app-loader>
      </ng-template>
    </button>
  </div>
</div>
