import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

import { ConfigService } from '../config.service';
import { AnalyticsListener } from './analytics-listener';

@Injectable()
export class AnalyticsService {
  constructor(
    private readonly gtmService: GoogleTagManagerService,
    private readonly analyticsListener: AnalyticsListener,
  ) {}

  init(): void {
    // we need analytics listener even if not prod in order to test
    // analytics in e2e tests
    this.analyticsListener.init();

    if (ConfigService.isProd()) {
      this.gtmService.addGtmToDom();
    }
  }
}
