import { Injectable } from '@angular/core';
import { combineLatest, map, mergeMap, Observable, of, tap } from 'rxjs';

import { Sha256Service } from '../../../analytics/sha-256.service';
import { AuthenticationService } from '../../../authentication/service/authentication.service';
import { EventDispatcher } from '../../../event-dispatcher/event-dispatcher';
import { SignupEvent } from '../../../event-dispatcher/events/signup-event';
import { NavigationService } from '../../../navigation/service/navigation.service';
import { PatientFormRequest } from '../api/model/patient-form-request';
import { PatientResponse } from '../api/model/patient-response';
import { SavePatientDataResponse } from '../api/model/save-patient-data-response';
import { PatientApi } from '../api/patient.api';
import { PatientForm } from '../model/patient-form';

@Injectable()
export class PatientService {
  constructor(
    private readonly patientApi: PatientApi,
    private readonly navigationService: NavigationService,
    private readonly authenticationService: AuthenticationService,
    private readonly eventDispatcher: EventDispatcher,
    private readonly sha256Service: Sha256Service,
  ) {}

  savePatientData(patientData: PatientForm) {
    const patientRequestData: PatientFormRequest = {
      address: {
        address: patientData.address,
        city: patientData.city,
        country: patientData.country,
        zipCode: patientData.postalCode,
        state: '',
      },
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      gender: patientData.gender,
      id: '',
      gtmCampaignUrl: patientData.gtmCampaignUrl,
      gtmReferrer: patientData.gtmReferrer,
    };
    return this.patientApi.savePatientData(patientRequestData).pipe(
      tap(() =>
        this.authenticationService.setDisplayName(
          patientData.firstName,
          patientData.lastName,
        ),
      ),
      tap((res) => this.dispatchSignupEvent(res)),
      tap(() => this.navigationService.navigateToRedirectionPath()),
    );
  }

  getPatient(): Observable<PatientResponse> {
    return this.patientApi
      .getPatient()
      .pipe(
        tap((res) =>
          res.isPatient
            ? this.authenticationService.patientData$.next(res.patient)
            : this.authenticationService.patientData$.next(null),
        ),
      );
  }

  isPatient(): Observable<boolean> {
    return this.getPatient().pipe(
      mergeMap((res) =>
        combineLatest([
          of(res),
          this.sha256Service.setEmailAndPhoneVariables(
            res.patient?.email,
            res.patient?.phoneNumber,
          ),
        ]),
      ),
      map((res) => res[0].isPatient),
    );
  }

  private dispatchSignupEvent(patientData: SavePatientDataResponse) {
    this.eventDispatcher.dispatch(
      new SignupEvent({
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        gender: patientData.gender,
        city: patientData.city,
        postalCode: patientData.postalCode,
        country: patientData.country,
        email: patientData.emailAddress,
        phone: patientData.phoneNumber,
        userId: patientData.userId.toString(),
        patientUuid: patientData.patientUuid,
      }),
    );
  }
}
