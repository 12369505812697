import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BaseApi } from '../../../base.api';
import { PatientFormRequest } from './model/patient-form-request';
import { PatientResponse } from './model/patient-response';
import { SavePatientDataResponse } from './model/save-patient-data-response';

@Injectable()
export class PatientApi extends BaseApi {
  savePatientData(
    patientData: PatientFormRequest,
  ): Observable<SavePatientDataResponse> {
    const url = this.baseUrl + '/booking/patients';

    return this.httpClient.post<SavePatientDataResponse>(url, patientData);
  }

  getPatient(): Observable<PatientResponse> {
    const url = this.baseUrl + '/booking/patients';

    return this.httpClient.get<PatientResponse>(url);
  }
}
