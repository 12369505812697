import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts$ = new BehaviorSubject<string[]>([]);

  show(message: string) {
    this.toasts$.next([...this.toasts$.value, message]);
  }

  remove(toast: string) {
    this.toasts$.next(this.toasts$.value.filter((t) => t !== toast));
  }
}
