import { inject, Injectable } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';

import { NavigationService } from '../../navigation/service/navigation.service';
import { VerificationService } from '../../verification/service/verification.service';
import { PatientService } from '../patient/service/patient.service';

@Injectable({ providedIn: 'root' })
export class FullAccessGuard {
  constructor(
    private readonly patientService: PatientService,
    private readonly navigationService: NavigationService,
    private readonly verificationService: VerificationService,
  ) {}

  canActivate(): Observable<boolean> {
    if (!localStorage.getItem('token')) return of(true);

    return this.verificationService.getVerificationStatus().pipe(
      switchMap((result) => {
        if (!result.verified) {
          const route = this.verificationService.getRouteToNavigateTo(result);
          if (route)
            this.navigationService.navigateToWithoutMedicalCenter(route);
          return of(false);
        } else
          return this.patientService.isPatient().pipe(
            tap((res) => {
              if (!res) {
                this.navigationService.navigateWithRedirectionPath(
                  'patient',
                  '',
                );
              }
            }),
          );
      }),
    );
  }
}

export const canActivate: CanActivateFn = () =>
  inject(FullAccessGuard).canActivate();
