<div class="container mt-5 mb-5">
  <div class="d-flex justify-content-center">
    <div class="code bg-white col-sm-10 col-12">
      <div class="text-center mb-2 notification p-4" i18n>
        Enter here the code you received by SMS on your cell phone
      </div>
      <div class="d-flex flex-column align-items-center">
        <div class="col-sm-10 col-12">
          <div class="d-flex flex-column align-items-center">
            <code-input
              [isCodeHidden]="false"
              [codeLength]="4"
              (codeCompleted)="onCodeCompleted($event)"
              (codeChanged)="onCodeChanged($event)"
              [initialFocusField]="0"
              data-testid="codeInput"
            >
            </code-input>
            <button
              class="btn secondary mt-4 w-50"
              (click)="verifyPhone()"
              type="submit"
              [disabled]="isConfirmButtonDisabled$ | async"
            >
              <span
                *ngIf="
                  (isConfirmButtonClicked$ | async) === false;
                  else loaderTemplate
                "
                i18n
                >Confirm</span
              >
              <ng-template #loaderTemplate>
                <app-loader
                  iconClasses="fa-1x"
                  class="d-flex justify-content-center align-items-center"
                ></app-loader>
              </ng-template>
            </button>
          </div>
        </div>
        <div class="col-md-12 p-3">
          <p i18n>
            The time of reception of the temporary code by SMS depends on the
            network of your operator. After 2 minutes, we invite you to check
            your phone number:
          </p>
          <p>
            <span i18n>- Your number is correct: </span>
            <span class="link-text" (click)="receiveAnotherCode()" i18n
              >Receive another code.
            </span>
          </p>
          <p>
            <span i18n>- Your number is incorrect: </span>
            <span class="link-text" i18n (click)="changePhoneNumber()"
              >Change your phone number.
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
