<div class="dialog-wrapper">
  <div class="fw-bold">
    <p i18n class="mb-0">Please enter your email address.</p>
    <p i18n class="mb-0">
      You will receive an email message with instructions on how to reset your
      password.
    </p>
  </div>
  <form
    class="col-md-8 d-flex flex-wrap justify-content-center"
    (submit)="submitClicked($event)"
  >
    <div class="form-floating app-label w-100">
      <input
        i18n-placeholder
        class="form-control form-input"
        type="email"
        placeholder="Your email *"
        [formControl]="email"
        autocomplete="email"
        data-testid="emailWithForgottenPasswordInput"
      />
      <label i18n>Your email *</label>
    </div>
    <button
      class="btn secondary text-font-medium mt-3 w-80"
      type="submit"
      [disabled]="email.invalid || (isSendNewPasswordButtonClicked$ | async)"
    >
      <span
        *ngIf="
          (isSendNewPasswordButtonClicked$ | async) === false;
          else loaderTemplate
        "
        i18n
        >Send new password</span
      >
      <ng-template #loaderTemplate>
        <app-loader
          iconClasses="fa-1x"
          class="d-flex justify-content-center align-items-center"
        ></app-loader>
      </ng-template>
    </button>
  </form>
</div>
